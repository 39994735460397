/* Pure CSS Loading Clock Using a Single Div */
.loadingClock {
    width: 46px;
    height: 46px;
    border: 3px solid #00c59a;
    border-radius: 100%;
    position: relative;
    margin:auto
}

.loadingClock:before,
.loadingClock:after {
    position: absolute;
    display: block;
    content: '';
    width: 3px;
    left: 19px; /* half width of clock - half width of hand */
    background: #00c59a;
    opacity: 1;
    top: 19px;
    transform-origin: 1.5px 1.5px;
    /*box-shadow: 0 0 2px #00C59A;*/
}

/* Animations */
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Big hand */
.loadingClock:before {
    height: 18px; /* half the width/height of the clock */
    animation: rotate 1.25s infinite linear;
}

.loadingClock:after {
    height: 14px; /* top + height for little hand must = top + height for big hand */
    animation: rotate 15s infinite linear; /* time should be big-hand time * 12 */
}

.loadingText {
    padding-top: 20px;
    font-size: 13px;
    color: #00c59a;
    text-align: center;
}
