.compactStatus {
    position: absolute;
    top: -4px;
    left: -4px;
    height: 8px;
    width: 8px;
    background: white;
    border-radius: 50%;
}

.status {
    position: absolute;
    top: -6px;
    left: -6px;
    height: 21px;
    width: 21px;
    font-size: 12px;
    background: white;
    border-radius: 50%;
}

.statusCenter {
    position: absolute;
    top: calc(50% - 9px);
    right: calc(50% - 10px);
    height: 23px;
    width: 23px;
    border-radius: 50%;
}

.compactStatusCenter {
    position: absolute;
    top: -10px;
    left: 3px;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    font-size: 10px;
    background: white;
    width: 18px;
    height: 15px;
    padding-left: 2px;
}


.statusText {
    position: absolute;
    bottom: 0;
    left: -9px;
    padding: 1px 5px;
    width: 64px;
    border-radius: 15px;
    background: #e3e3e3;
    height: 15px;
}

.statusText > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
