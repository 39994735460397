

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

body {
    background: white;
    overflow: hidden;
    font-family: "Open Sans", sans-serif;
}


.flex {
    display: flex;
    display: -webkit-flex;
}

a {
    text-decoration: none;
}

@keyframes flickerAnimation {
    0% {
        opacity: 0.6;
    }
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 0.6;
    }
}

@-o-keyframes flickerAnimation {
    0% {
        opacity: 0.6;
    }
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 0.6;
    }
}

@-moz-keyframes flickerAnimation {
    0% {
        opacity: 0.6;
    }
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 0.6;
    }
}

@-webkit-keyframes flickerAnimation {
    0% {
        opacity: 0.6;
    }
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 0.6;
    }
}

.animate-flicker {
    -webkit-animation: flickerAnimation 1s infinite;
    -moz-animation: flickerAnimation 1s infinite;
    -o-animation: flickerAnimation 1s infinite;
    animation: flickerAnimation 1s infinite;
}


.users-count-fixed-width {
    width: 200px;
}

.timezone-header {
    padding-left: 6px;
}

.current-localtime {
    font-size: 16px;
    font-weight: 500;
    min-width: 70px;
}

.user-localtime {
    /*background-color: #dddddd;*/
    color: #00c49b;
    max-width: 80px;
    padding: 2px;
    margin-top: -4px;
    padding-left: 4px;
    margin-left: -4px;
    font-weight: 700;
    border: 1px solid;
}

.current-localcity {
    font-size: 10px;
    margin-top: 3px;
    margin-bottom: 5px;
    text-transform: uppercase;
    /*max-width: 80px;*/

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
}

.grouped-users-label {
    font-size: 11px;
    color: #666;
    margin-bottom: 20px;
}

.date {
    color: #bbbbbb;
}

.grouped-users {
    background: #00c49b;
    color: white;
    border-radius: 50%;
    min-height: 12px;
    padding: 2px 5px 5px;
    max-width: 97%;
    max-height: 400px;
    min-width: 7px;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.users-container {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap; /* Safari 6.1+ */
}

@media (max-width: 1000px) {
    #fav-btn {
        display: none;
    }
}

@media (max-width: 860px) {
    #about-btn {
        display: none;
    }

    .header-checkbox-container {
        display: none !important;
    }
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 7px;
    top: 3px;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.trial-button {
    border: 1px solid #00c49b;
    padding: 3px;
    color: white;
    background: #00c49b;
    cursor: pointer;
    font-size: 9px;
    text-align: center;
    width: 49px;
    margin-right: -4px;
}

a {
    color: rgb(0, 196, 155);
}
