.groupContainer {
    padding: 10px;
    display: inline-block;
    flex: 1;
    z-index: 12;
    width: 490px;
    max-width: 100%;
    height: 50px;
    padding-left: 15px;
}

.groupContainer > * {
    display: inline-block;
    vertical-align: middle;
}

.logo {
    width: 20px;
    height: 20px;
}

.appTitle {
    padding-left: 5px;
    min-width: 70px;
}

.greyIcon {
    color: #aaaaaa;
    vertical-align: middle;
    margin-left: 8px;
    transition: transform .2s ease;
    border-radius: 50%;
    font-size: 30px;
}

.greyIconClosed {
    transform: rotate(180deg);
}

.greyIcon:hover {
    cursor: pointer;
    background: #e4e4e4;
}

.changeTimeParent {
    display: inline-flex;
    width: 300px;
    min-height: 40px;
    vertical-align: top;
    overflow: hidden;
    transition: all .2s ease;
}

.changeTimeParentClosed {
    width: 0;
    opacity: 0;
}

.changeTimeInput {
    flex: 1;
    padding: 0 30px;
    padding-top: 3px;
}

.changeInputButton {
    vertical-align: top;    
    color: white;
    top: -3px;
}
