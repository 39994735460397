.teamInfoContainer {
  color: black;
  text-align: left;
  background: #fefefe;
  border-bottom: 1px solid #eee;
  width: 100%;
  margin-left: 0;
  padding: 15px 15px 15px 50px;
  z-index: 4;
  display: flex;
  align-items: center;
  height: 80px;
}
