@import "@fontsource/open-sans";
@import "@fontsource/open-sans/500.css";
@import "@fontsource/open-sans/600.css";
@import "@fontsource/open-sans/700.css";
@import 'material-icons/iconfont/material-icons.css';

.js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
}
