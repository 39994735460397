.user {
  display: inline-block;
  margin-left: 2px;
  text-align: center;
  padding: 8px;
  position: relative;
  transition: filter 0.5s ease;
  padding-right: 0px;
}

.user:hover .uc {
  display: block;
}

.uc {
  display: none;
}

.UserAvatar {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  transition: filter 0.5s ease;
}

.status {
  position: absolute;
  top: -3px;
  right: 4px;
  height: 16px;
  width: 16px;
  background: white;
  border-radius: 50%;
}

.statusCenter {
  position: absolute;
  top: calc(50% - 5px);
  right: calc(50% - 5px);
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.active {
  background: #00C49B;
}

.away {
  background: #dddddd;
}

.loading {
  background: #fcf2d4;
}

.sleep {
  filter: grayscale(100%) brightness(70%);
}

.sleep:hover {
  filter: grayscale(0%) brightness(100%);
}

.userName {
  text-transform: capitalize;
  text-align: center;
  max-width: 50px;
  height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  color: #999;
  font-size: 12px;
  white-space: nowrap;
}

.compactUserBox {
  position: relative;
  margin: auto;
  background: #fff;
  border: 1px solid #00c49b;
  padding: 3px;
  border-radius: 5px;
  width: 57px;
  margin: 4px;
}

.compactStatus {
  position: absolute;
  top: -4px;
  right: -4px;
  height: 8px;
  width: 8px;
  background: white;
  border-radius: 50%;
}

.compactUserName {
  text-transform: capitalize;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #333;
  font-size: 9px;
  white-space: nowrap;
}

.compactStatusCenter {
  position: absolute;
  top: calc(50% - 3px);
  right: calc(50% - 3px);
  height: 6px;
  width: 6px;
  border-radius: 50%;
}
