.container {
  display: flex;
  padding: 10px;
  background: #fefefe;
  border-bottom: 1px solid #eee;
}

.container .filters {
  max-width: 800px;
  font-size: 12px
}

.container.mobile .filters {
  max-width: 100%;
}

.mobile {
  flex-direction: column;
}
