
.trailIndicator {
    border: 1px solid #00c49b;
    padding: 3px;
    color: white;
    background: #00c49b;
    cursor: pointer;
    font-size: 10px;
    text-align: center;
    width: 65px;
    margin-right: -4px;
    display: block;
}
