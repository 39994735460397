.card {
  text-align: center;
  position: absolute;
  width: 150px;
  padding: 10px;
  background: white;
  border: 1px solid #00c39b;
  z-index: 4;
  left: -42px;
  top: -3px;
  border-radius: 15px;
  box-shadow: 0 0 8px 0 rgb(158 158 158);
}

.UserAvatar {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    margin: auto;
}

.email, .email a {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
